<template>
  <div>
    <v-container align="center">
      <v-row align="center" justify="center">
        <v-flex>
          <v-img src="@/assets/sponsers/R4DM24_sponsorilogot/R4DM24_valostore_logo.png" contain max-width="200px" style="cursor: pointer" @click.stop="openNewTab('https://www.valostore.fi/')"></v-img>
        </v-flex>
        <v-flex>
          <v-img src="@/assets/sponsers/R4DM24_sponsorilogot/R4DM24_ecoaims_logo.png" contain max-width="200px" style="cursor: pointer" @click.stop="openNewTab('https://www.ecoaims.com')"></v-img>
        </v-flex>
        <v-flex>
          <v-img src="@/assets/sponsers/R4DM24_sponsorilogot/R4DM24_mk_facade_logo.png" contain max-width="200px" style="cursor: pointer" @click.stop="openNewTab('https://www.mkfacade.fi/')"></v-img>
        </v-flex>
        <v-flex>
          <v-img src="@/assets/sponsers/R4DM24_sponsorilogot/R4DM24_nordic_drones_logo.png" contain max-width="220px" style="cursor: pointer" @click.stop="openNewTab('https://nordicdrones.fi/')"></v-img>
        </v-flex>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Sponsers",
  methods: {
    openNewTab(link) {
      window.open(link, '_blank').focus();
    }
  },
}
</script>

<style scoped>
  .centerer {
    text-align: center;
    padding-top: 25px;
  }
  .centerer a {
    text-decoration: none;
  }
</style>