<template>
  <div class="home home-page">
    <v-parallax
        dark
        src="@/assets/r4dm_hero_2024.png"
        jumbotron
        height="100%"
    >

      <v-row
          class="text-center"
          cols="12"
          align="center"
          justify="center"
      >
        <v-img
            class="align-self-center"
            src="@/assets/resul_four_day_march_logo_transbg_2023.png"
            max-width="200px"
        />
      </v-row>
      <v-row
          align="center"
          justify="center"
      >
        <v-col
            class="text-center"
            cols="12"
            justify="center"
        >
          <h1 class="display-1 font-weight-thin mb-4">
            {{ $t('old-results-header-2023') }}
          </h1>
          <h2 class="font-weight-thin mb-4">
            {{ $t('old-results-intro-header-2023') }}
          </h2>

        </v-col>
       
      </v-row>
<!--       <v-row
          class="text-center"
          cols="12"
          align="center"
          justify="center"
      >
        <v-btn
            @click.stop="$router.push('/signup')"
            v-if="!$store.getters.isAuthenticated"
        >
          <span>{{ $t('register') }}</span>
        </v-btn>
      </v-row> -->
    </v-parallax>

    <v-container class="lighten-3" fluid>
      <v-row>
        <v-col
            cols="12"
            justify="center"
        >
          <OldResultsView :year="2023"></OldResultsView>
        </v-col>

      </v-row>
    </v-container>

  </div>
</template>

<script>
// @ is an alias to /src
import OldResultsView from '@/views/OldResultsView'

export default {
  name: 'Home',
  components: {
    OldResultsView,
  }
}
</script>

<style>
.home-page {
  color: black;
}

.v-parallax {
  height: auto !important;
  padding-top: 120px !important;
  padding-bottom: 50px;
}

.gray {
  background-color: #e0dfdf !important;
}

.blue-background {
  background-color: #062F6E;
}

.text--white {
  color: white;
}
</style>
