<template>
  <v-container class="grey lighten-5">
    <v-row no-gutters>
      <v-col cols="12" class="text-center">
        <h1>Results</h1>
      </v-col>
      <v-col cols="12" class="text-center">
        <v-select
            :items="items"
            v-model="select"
            label="Select Days"
        ></v-select>

      </v-col>
      <v-col cols="12">
        <v-simple-table>
          <template v-slot:default>
            <v-lazy
                v-model="isActive"
                :options="{
              threshold: .5
              }"
                transition=""
            >
              <template>
                <thead>
                <tr>
                  <th class="text-left">
                    {{ $t('name') }}
                  </th>
                  <span v-if="select == 'all' || select == 'day1'">
                    <th class="text-left, bg1">
                      {{ $t('day') }}
                    </th>
                    <th class="text-left, bg1">
                      {{ $t('distance') }}
                    </th>
                    <th class="text-left, bg1">
                      {{ $t('time') }}
                    </th>
                    <th class="text-left, bg1">
                      {{ $t('proof-image') }}
                    </th>
                    <th class="text-left, bg1">
                      {{ $t('proof-image-1') }}
                    </th>
                    <th class="text-left, bg1">
                      {{ $t('proof-image-2') }}
                    </th>
                    <th class="text-left, bg1">
                      {{ $t('approve') }}
                    </th>
                  </span>
                  <span v-if="select == 'all' || select == 'day2'">
                    <th class="text-left, bg2">
                      {{ $t('day') }}
                    </th>
                    <th class="text-left, bg2">
                      {{ $t('distance') }}
                    </th>
                    <th class="text-left, bg2">
                      {{ $t('time') }}
                    </th>
                    <th class="text-left, bg2">
                      {{ $t('proof-image') }}
                    </th>
                    <th class="text-left, bg2">
                      {{ $t('proof-image-1') }}
                    </th>
                    <th class="text-left, bg2">
                      {{ $t('proof-image-2') }}
                    </th>
                    <th class="text-left, bg2">
                      {{ $t('approve') }}
                    </th>
                  </span>
                  <span v-if="select == 'all' || select == 'day3'">
                    <th class="text-left, bg3">
                      {{ $t('day') }}
                    </th>
                    <th class="text-left, bg3">
                      {{ $t('distance') }}
                    </th>
                    <th class="text-left, bg3">
                      {{ $t('time') }}
                    </th>
                    <th class="text-left, bg3">
                      {{ $t('proof-image') }}
                    </th>
                    <th class="text-left, bg3">
                      {{ $t('proof-image-1') }}
                    </th>
                    <th class="text-left, bg3">
                      {{ $t('proof-image-2') }}
                    </th>
                    <th class="text-left, bg3">
                      {{ $t('approve') }}
                    </th>
                  </span>
                  <span v-if="select == 'all' || select == 'day4'">
                    <th class="text-left, bg4">
                      {{ $t('day') }}
                    </th>
                    <th class="text-left, bg4">
                      {{ $t('distance') }}
                    </th>
                    <th class="text-left, bg4">
                      {{ $t('time') }}
                    </th>
                    <th class="text-left, bg4">
                      {{ $t('proof-image') }}
                    </th>
                    <th class="text-left, bg4">
                      {{ $t('proof-image-1') }}
                    </th>
                    <th class="text-left, bg4">
                      {{ $t('proof-image-2') }}
                    </th>
                    <th class="text-left, bg4">
                      {{ $t('approve') }}
                    </th>
                  </span>
                </tr>

                </thead>
                <tbody>
                <tr
                    v-for="item in results"
                    :key="item.name"
                >
                  <td v-if="item.user.nick_name && item.user.nick_name !== ''">{{ item.user.nick_name }}</td>
                  <td v-else>{{ item.user.first_name }}, {{ item.user.last_name }}</td>
                  <span v-if="select == 'all' || (select == 'day1' && item.accepted_1 == false)">
                  <td class="bg1">{{ item.day_1 }}</td>
                  <td class="bg1">{{ item.distance_1 }}</td>
                  <td class="bg1">{{ item.time_1 }}</td>
                  <td>
                    <enlargeable-image :src="base_url+ item.image_path_1_1"
                                       :src_large="base_url+ item.image_path_1_1"
                                       max-height="150px" max-width="150px"/>
                  </td>
                  <td>
                    <enlargeable-image :src="base_url+ item.image_path_1_2"
                                       :src_large="base_url+ item.image_path_1_2"
                                       max-height="150px" max-width="150px"/>
                  </td>
                  <td>
                    <enlargeable-image :src="base_url+ item.image_path_1_3"
                                       :src_large="base_url+ item.image_path_1_3"
                                       max-height="150px" max-width="150px"/>
                  </td>
                  <td class="bg1">
                    <v-checkbox
                        v-model="item.accepted_1"
                    ></v-checkbox>
                  </td>
                  </span>
                  <span v-if="select == 'all' || (select == 'day2' && item.accepted_2 == false)">

                  <td class="bg2">{{ item.day_2 }}</td>
                  <td class="bg2">{{ item.distance_2 }}</td>
                  <td class="bg2">{{ item.time_2 }}</td>
                  <td>
                    <enlargeable-image :src="base_url+ item.image_path_2_1"
                                       :src_large="base_url+ item.image_path_2_1"
                                       max-height="150px" max-width="150px"/>
                  </td>
                  <td>
                    <enlargeable-image :src="base_url+ item.image_path_2_2"
                                       :src_large="base_url+ item.image_path_2_2"
                                       max-height="150px" max-width="150px"/>
                  </td>
                  <td>
                    <enlargeable-image :src="base_url+ item.image_path_2_3"
                                       :src_large="base_url+ item.image_path_2_3"
                                       max-height="150px" max-width="150px"/>
                  </td>
                  <td class="bg2">
                    <v-checkbox
                        v-model="item.accepted_2"
                    ></v-checkbox>
                  </td>
                  </span>
                  <span v-if="select == 'all' || (select == 'day3' && item.accepted_3 == false)">
                  <td class="bg3">{{ item.day_3 }}</td>
                  <td class="bg3">{{ item.distance_3 }}</td>
                  <td class="bg3">{{ item.time_3 }}</td>
                  <td>
                    <enlargeable-image :src="base_url+ item.image_path_3_1"
                                       :src_large="base_url+ item.image_path_3_1"
                                       max-height="150px" max-width="150px"/>

                  </td>
                  <td>
                    <enlargeable-image :src="base_url+ item.image_path_3_2"
                                       :src_large="base_url+ item.image_path_3_2"
                                       max-height="150px" max-width="150px"/>
                  </td>
                  <td>
                    <enlargeable-image :src="base_url+ item.image_path_3_3"
                                       :src_large="base_url+ item.image_path_3_3"
                                       max-height="150px" max-width="150px"/>

                  </td>
                  <td class="bg3">
                    <v-checkbox
                        v-model="item.accepted_3"
                    ></v-checkbox>
                  </td>
                  </span>
                  <span v-if="select == 'all' || (select == 'day4' && item.accepted_4 == false)">

                  <td class="bg4">{{ item.day_4 }}</td>
                  <td class="bg4">{{ item.distance_4 }}</td>
                  <td class="bg4">{{ item.time_4 }}</td>
                  <td>
                    <enlargeable-image :src="base_url+ item.image_path_4_1"
                                       :src_large="base_url+ item.image_path_4_1"
                                       max-height="150px" max-width="150px"/>
                  </td>
                  <td>
                    <enlargeable-image :src="base_url+ item.image_path_4_2"
                                       :src_large="base_url+ item.image_path_4_2"
                                       max-height="150px" max-width="150px"/>
                  </td>
                  <td>
                    <enlargeable-image :src="base_url+ item.image_path_4_3"
                                       :src_large="base_url+ item.image_path_4_3"
                                       max-height="150px" max-width="150px"/>
                  </td>
                  <td class="bg4">
                    <v-checkbox
                        v-model="item.accepted_4"
                    ></v-checkbox>
                  </td>
                  </span>
                </tr>
                </tbody>
              </template>
            </v-lazy>
          </template>
        </v-simple-table>
      </v-col>
      <v-col cols="12">
        <v-btn color="primary" @click.stop="save()">{{
            $t("save")
          }}
        </v-btn>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Axios from '@/api/apiconfig'
import EnlargeableImage from '@diracleo/vue-enlargeable-image';

export default {
  name: "CompetitionAdminUnapproved",
  components: {EnlargeableImage},

  data: () => ({
    results: [], //
    items: ['all', "day1", "day2", "day3", "day4"],
    select: "all",
    base_url: 'https://api.resulfourdaymarch.fi/v1.0/competition/'
  }),
  methods: {
    getResults() {
      Axios.get('/competition/unapproved_results', {withCredentials: true}).then(data => {
        if (data) {
          this.results = data.data.data.competition

          for (let i = 0; i < this.results.length; i++) {
            let result = this.results[i]

            if (result.distance_1) {
              result.distance_1 = result.distance_1.toFixed(1)
            }
            if (result.distance_2) {
              result.distance_2 = result.distance_2.toFixed(1)
            }
            if (result.distance_3) {
              result.distance_3 = result.distance_3.toFixed(1)
            }
            if (result.distance_4) {
              result.distance_4 = result.distance_4.toFixed(1)
            }
          }
        }
      })
    },
    save() {
      Axios.post('/competition/approve_results', this.results, {withCredentials: true}).then(data => {

      });
    }
  },
  mounted() {
    this.getResults()
  }
}
</script>

<style scoped>
.bg1 {
  background-color: lightgray;
}

.bg2 {
  background-color: lightcoral;
}

.bg3 {
  background-color: lightgreen;
}

.bg4 {
  background-color: lightblue;
}

.text-left {
  padding-right: 5px;
}
span {
  display: contents;
}
</style>
