<template>
  <v-container id="signup" fluid fill-height class="signup" :style=img_section_style>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md12>
        <v-col sm="9" md="12" class="text-center">
          <h1 class="display-1 font-weight-thin mb-4">{{ $t("password-resetted") }}</h1>
          <h1 class="display-1 font-weight-thin mb-4" @click="$router.push('/login')" style="cursor: pointer;text-decoration: underline;">{{ $t("login") }}</h1>
        </v-col>
      </v-flex>
    </v-layout>
  </v-container>

</template>

<script>
export default {
  name: "ResetPasswordDone",
  computed: {
    img_section_style: function () {
      var bgImg = require('@/assets/r4dm_hero_2024.png')
      return {
        "background": 'url(' + bgImg + ')',
        "background-color": "#062F6E !important",
        "background-size": "cover",
      }
    },
    rules() {
      return [
        this.selected.filter(s => s === true).length === 2 || this.$t("policies-must-accepted")
      ]
    }
  }
}
</script>

<style scoped>
.signup {
  background-color: #062F6E !important;
  color: white !important;
}
</style>