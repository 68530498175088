<template>
  <v-container id="resetpwmain" fluid fill-height class="signup" :style=img_section_style>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-col sm="9" md="12" class="text-center">
          <h1 class="display-1 font-weight-thin mb-4">{{ $t("reset-password") }}</h1>
        </v-col>
        <v-form ref="form" v-model="valid" dark color="white">
          <v-layout align-center justify-center>
            <v-flex sm12 md12>
              <v-text-field
                  id="password"
                  name="password"
                  :label="$t('password')"
                  v-model="user.password"
                  type="password"
                  :disabled="loading"
                  filled
                  rounded
                  background-color="#fff"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout align-center justify-center>
            <v-flex sm12 md12>
              <v-text-field
                  id="password_repeat"
                  name="password_repeat"
                  :label="$t('password-repeat')"
                  v-model="user.password_repeat"
                  type="password"
                  :disabled="loading"
                  filled
                  background-color="#fff"
                  rounded
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>

        <v-flex align-self-center sm12 md6>
          <v-spacer></v-spacer>
          <v-btn color="white" @click.stop="resetPass()" :loading="loading">{{
              $t("reset-password")
            }}
          </v-btn>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-snackbar
        v-model="snackbar"
    >
      {{ $t(resp) }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>

</template>

<script>
import API from "@/api/apiconfig";

export default {
  name: "ResetPassword",
  data: () => ({
    user: {
      password: null,
      password_repeat: null,
    },
    valid: false,
    loading: false,
    resp: null,
    snackbar: false,
    token: null
  }),
  methods: {
    resetPass() {
      API.post('/auth/reset/' + this.token, this.user).then(data => {
        if (data) {
          if (data.status == 201) {
            this.$router.push("/reset_done")
          } else {
            this.resp = data.data.message;
            this.snackbar = true;
          }
        }
      })
    }
  },
  computed: {
    img_section_style: function () {
      var bgImg = require('@/assets/r4dm_hero_2024.png')
      return {
        "background": 'url(' + bgImg + ')',
        "background-color": "#062F6E !important",
        "background-size": "cover",
      }
    },
  },
  mounted() {
    this.token = this.$router.currentRoute.params.token;
  }

}
</script>

<style scoped>
.signup {
  background-color: #062F6E !important;
  color: white !important;
  padding-top: 30px;
}
</style>