<template>
  <v-container id="loginbg" fluid fill-height class="signup" :style=img_section_style>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-col sm="9" md="12" class="text-center">
          <h1 class="display-1 font-weight-thin mb-4">{{ $t("login") }}</h1>
        </v-col>
        <v-form ref="form" v-model="valid" dark color="white">
          <v-layout align-center justify-center>
            <v-flex sm12 md12>
              <v-text-field
                  name="login"
                  :label="$t('email')"
                  type="text"
                  v-model="user.email"
                  filled
                  rounded
                  background-color="#fff"
                  :disabled="loading"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout align-center justify-center>
            <v-flex sm12 md12>
              <v-text-field
                  id="password"
                  name="password"
                  :label="$t('password')"
                  v-model="user.password"
                  :type="showPassword ? 'text' : 'password'"
                  :disabled="loading"
                  @keydown.enter="login()"
                  filled
                  rounded
                  background-color="#fff"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>
        <v-flex sm12 md12>
          <p @click="$router.push('/reset')" class="mr-2"
             style="cursor: pointer;">{{ $t('reset-password') }}</p>
        </v-flex>
        <v-flex align-self-center sm12 md6  style="padding-bottom: 70px;">
          <v-spacer></v-spacer>
          <v-btn color="white" @click.stop="login()" :loading="loading">{{
              $t("login")
            }}
          </v-btn>
        </v-flex>
      </v-flex>

    </v-layout>
    <v-snackbar
        v-model="snackbar"
    >
      {{ $t(resp) }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>

</template>

<script>
export default {
  name: 'login',
  data: () => ({
    valid: false,
    user: {
      email: null,
      password: null,
    },
    showPassword: false,
    loading: false,
    snackbar: false,
    resp: null
  }),
  methods: {
    login() {
      this.loading = true;
      if (this.$refs.form.validate()) {
        this.$store.dispatch('userLogin', this.user).then((response) => {
          if (response) {

            this.$store.dispatch('checkLogin').then((response) => {
              if (response) {
                this.$router.push('/edit_user');
              }
            });
            if (response.response.status == 400) {
              this.snackbar = true;
              this.resp = response.response.data.message;
            }
            //Check if we were redirected to login from somewhere
          }
          this.loading = false;
        }).catch(error => {
          this.snackbar = true;
          console.log(error.data)
        });
      }
    },
    checkLogin() {
      this.$store.dispatch('checkLogin').then((response) => {
        if (response) {
          this.$router.push('/');
        }
      });
    },
  },
  mounted() {
    this.checkLogin()
  },
  computed: {
    img_section_style: function () {
      var bgImg = require('@/assets/r4dm_hero_2024.png')
      return {
        "background": 'url(' + bgImg + ')',
        "background-color": "#062F6E !important",
        "background-size": "cover",
      }
    },
  }
};
</script>

<style>
.signup {
  background-color: #062F6E !important;
  color: white !important;
  padding-top: 60px;
}
</style>
