<template>
  <v-container id="" fluid fill-height class="signup" :style=img_section_style>
    <v-layout align-center justify-center>
      <v-flex xs12 sm10 md6>
        <v-col sm="9" md="12" class="text-center">
          <h1 class="display-1 font-weight-thin mb-4">{{ $t("enter-results") }}</h1>
        </v-col>
        <v-form ref="form" v-model="valid" dark color="white">
          <v-layout align-center justify-center>
            <v-flex sm12 md12>
              <v-flex sm1 md1>
                <v-tooltip top dark>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" dark>info</v-icon>
                  </template>
                  <span>{{$t('date-tooltip')}}</span>
                </v-tooltip>
              </v-flex>
              <v-select
                  v-model="CompetitionEntry.day"
                  :items="items"
                  item-text="date"
                  item-value="day"
                  :label="$t('competition-day')"
                  filled
                  rounded
                  background-color="#fff"
              ></v-select>
            </v-flex>
          </v-layout>
          <v-layout align-center justify-center>
            <v-flex sm12 md12>
            <v-flex sm1 md1>
              <v-tooltip top dark>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" dark>info</v-icon>
                </template>
                <span>{{$t('distance-tooltip')}}</span>
              </v-tooltip>
            </v-flex>
              <v-text-field
                  name="distance"
                  :label="$t('distance')"
                  type="number"
                  v-model="CompetitionEntry.distance"
                  :rules="[v => !!v || 'Item is required',
                  () => !!CompetitionEntry.distance && CompetitionEntry.distance <= 123 && CompetitionEntry.distance > 0 || 'Distance must be less than 123km']"
                  :disabled="loading"
                  required
                  filled
                  rounded
                  background-color="#fff"
              ></v-text-field>
            </v-flex>
          </v-layout>
          <v-layout align-center justify-center>
            <v-flex sm12 md12>
              <v-flex sm1 md1>
                <v-tooltip top dark>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" dark>info</v-icon>
                  </template>
                  <span>{{$t('time-tooltip')}}</span>
                </v-tooltip>
              </v-flex>
              <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="CompetitionEntry.time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                  filled
                  rounded
                  background-color="#fff"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="CompetitionEntry.time"
                      :label="$t('time')"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      :rules="[v => !!v || 'Item is required']"
                      :disabled="loading"
                      required
                      filled
                      rounded
                      background-color="#fff"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu2"
                    v-model="CompetitionEntry.time"
                    full-width
                    format="24hr"
                    @click:minute="$refs.menu.save(CompetitionEntry.time)"
                    filled
                    rounded
                    background-color="#fff"
                ></v-time-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <v-layout align-center justify-center>
            <v-flex sm12 md12>
              <v-switch
                  v-model="CompetitionEntry.extra_weight"
                  :label="$t('extra-weight')"
                  color="#FFF"
                  dark
              ></v-switch>
            </v-flex>
          </v-layout>
          <v-layout align-center justify-center>
            <v-flex sm12 md12>
              <p class="mr-2">{{ $t('image-desc') }}</p>
            </v-flex>
          </v-layout>
          <v-layout align-center justify-center row>
            <v-flex xs12 sm4 class="justify-center btn-margin">
              <upload-dialog
                  v-model="file"
                  accept=".jpg,.jpeg,.png"
                  btn-name="image-1"             
              ></upload-dialog>
            </v-flex>
            <v-flex xs12 sm4 class="justify-center btn-margin">
              <upload-dialog
                  v-model="file_1"
                  accept=".jpg,.jpeg,.png"
                  btn-name="image-2"
              ></upload-dialog>
            </v-flex>
            <v-flex xs12 sm4 class="justify-center btn-margin">
              <upload-dialog
                  v-model="file_2"
                  accept=".jpg,.jpeg,.png"
                  btn-name="image-3"
              ></upload-dialog>
            </v-flex>
          </v-layout>

          <v-layout align-center justify-center>
          <v-flex>
            <v-spacer></v-spacer>
            <v-btn large rounded style="margin-top: 35px; margin-bottom: 30px;" color="primary" @click.stop="AddToCompetition" :loading="loading"
                  :disabled="isAddButtonDisabled">{{
                $t("submit")
              }}
            </v-btn>
            <!-- <v-layout align-center justify-center class="denied-btn">
              <p class="mr-2">{{ $t('submit-not-available') }}</p>
              <v-btn large rounded style="margin-top: 35px; margin-bottom: 30px;" color="primary"
                  :disabled=true>{{
                $t("submit")
              }}
            </v-btn>
          </v-layout> -->
          </v-flex>
          </v-layout>
        </v-form>

      </v-flex>
    </v-layout>
    <v-snackbar
        v-model="snackbar"
    >
      {{ $t(resp) }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>

</template>

<script>
import UploadDialog from '@/components/UploadDialog';
import Axios from "@/api/apiconfig";

export default {
  components: {
    UploadDialog
  },
  name: "CompetitionEntry",
  data: () => ({
    CompetitionEntry: {
      day: null,
      extra_weight: false,
      distance: null,
      time: null,
    },
    modal2: false,
    loading: false,
    menu2: false,
    snackbar: false,
    file: null,
    file_1: null,
    file_2: null,
    imageUrl: null,
    resp: null,
    valid: null,
    disabled: true,
  }),
  computed: {
    items: function () {
      return [
        {date: this.$t("day-one"), day: 1},
        {date: this.$t("day-two"), day: 2},
        {date: this.$t("day-three"), day: 3},
        {date: this.$t("day-four"), day: 4}
      ]
    },
    img_section_style: function () {
      var bgImg = require('@/assets/r4dm_hero_2024.png')
      return {
        "background": 'url(' + bgImg + ')',
        "background-color": "#062F6E !important",
        "background-size": "cover",
      }
    },
    isAddButtonDisabled() {
      return !((this.file || this.file_1 || this.file_2) && this.CompetitionEntry.day && this.CompetitionEntry.distance && this.CompetitionEntry.time);
      // means u have neither name nor site
    },
  },
  methods: {
    validate() {
      return this.$refs.form.validate()
    },
    AddToCompetition() {
      let form_validated = this.validate()
      if (form_validated) {

        let body = new FormData();
        body.append('file', this.file);
        body.append('file_1', this.file_1);
        body.append('file_2', this.file_2);
        body.append('day', this.CompetitionEntry.day);
        body.append('extra_weight', this.CompetitionEntry.extra_weight);
        body.append('distance', this.CompetitionEntry.distance);
        body.append('time', this.CompetitionEntry.time);

        let req = Axios.post('/competition/', body, {withCredentials: true}, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        }).then(data => {
          //console.log(data)
          if (data) {
            if (data.status == 201) {
              this.$router.push("/entry_done")
            } else {
              this.resp = data.data.message;
              this.snackbar = true;
            }
          }
        }).catch(error => {
          console.log(error)
          this.resp = error.response.data.message;
          this.snackbar = true;
        });
      }
    }
  },
}
</script>

<style scoped>
.signup {
  background-color: #062F6E !important;
  color: white !important;
  padding-top: 30px;
}
.btn-margin {
  margin-top: 20px;
}

.denied-btn {
  margin-top: 30px;
  margin-bottom: 30px;
}

</style>