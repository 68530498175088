<template>
  <div>
    <template v-if="!fileFile">
      <v-btn color="primary" @click="pickFile()">
         <v-icon dark left>
          mdi-cloud-upload
        </v-icon>
        {{$t(btnName)}}

        <input
            type="file"
            style="display: none;"
            ref="file"
            @change="onFilePicked"
            :accept="accept"
        >
      </v-btn>
    </template>
    <template v-else>
      <div>
      <v-btn @click="loseFile()" class="uploadButtonStyle">
        <v-icon
          dark
          left
        >
          mdi-minus-circle
        </v-icon>
        {{fileName}}
        </v-btn>
    </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'uploaddialog',
  props: {
    value: {
      required: false
    },
    accept: {
      type: String,
      required: false
    },
    btnName: {
      type: String,
      required: true
    }
  },
  data: () => ({
    fileName: '',
    fileFile: null,
    fileUrl: ''
  }),
  methods: {
    pickFile() {
      this.$refs.file.click();
    },
    onFilePicked(e) {
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.fileName = files[0].name;
        if (this.fileName.lastIndexOf('.') <= 0) {
          return;
        }
        const fr = new FileReader();
        fr.readAsDataURL(files[0]);
        fr.addEventListener('load', () => {
          this.fileUrl = fr.result;
          this.fileFile = files[0]; // this is an image file that can be sent to server...
          this.$emit('input',this.fileFile);
          this.$emit('file-picked', this.fileFile);
        });


      } else {
        this.fileName = '';
        this.fileFile = null;
        this.fileUrl = '';
      }
    },
    loseFile() {
      this.fileName = '';
      this.fileFile = null;
      this.fileUrl = '';
      this.$emit('input',this.fileFile);
      this.$emit('file-picked', this.fileFile);
    },
  }
};
</script>