<template>
  <v-dialog v-model="show" max-width="800px">
    <v-card>
      <v-container>
        <div v-html="$t('sports-federation-dialogue')"/>

        <v-card-actions>
          <v-btn color="primary" flat @click.stop="show=false">Close</v-btn>
        </v-card-actions>
      </v-container>
    </v-card>

  </v-dialog>


</template>

<script>
export default {
  name: "SportFederationDialog",
  props: {
    value: Boolean
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>