<template>
  <div>
    <v-parallax
        dark
        src="@/assets/r4dm_hero_2024.png"
        jumbotron
        height="100%"
    >

      <v-row
          class="text-center"
          cols="12"
          align="center"
          justify="center"
      >
        <v-img
            class="align-self-center"
            src="@/assets/R4DM24_Annual_Patch_red_ol_2024_01_10-1_transparent_cropped3.png"
            max-width="200px"
        />
      </v-row>
      <v-row
          align="center"
          justify="center"
      >
        <v-col
            class="text-center"
            cols="12"
            justify="center"
        >
          <h1 class="display-1 font-weight-thin mb-4">
            {{ $t('info-header') }}
          </h1>
          <h2 class="font-weight-thin mb-4">
            {{ $t('info-header-secondary') }}
          </h2>
        </v-col>
      </v-row>
      <div class="width-controller">
        <v-container fluid>
          <v-layout align-center justify-center row>
            <v-flex sm12 md4>
              <div class="text-md-left text-lg-left text-xl-left text-center">
                <v-btn
                    class=" btn-padded"
                >
                  <a v-if="$i18n.locale==='fi'"
                     style="text-decoration: none;color:#062F6E;padding: 10px 25px;"
                     href="/files/RESUL_Four_Day_March_mitali.pdf" download><span><v-icon
                      v-on="on" dark>file_download</v-icon> {{ $t('resul-pdf-medal') }}</span></a>
                  <a v-if="$i18n.locale==='en'"
                     style="text-decoration: none;color:#062F6E;padding: 10px 25px;"
                     href="/files/R4DM_Medal_and_Pins_Info_ENG_2021_05_31.pdf" download><span><v-icon
                      v-on="on" dark>file_download</v-icon> {{ $t('resul-pdf-medal') }}</span></a>
                </v-btn>
              </div>
            </v-flex>
            <v-flex sm12 md4>
              <div class="text-center">
                <v-btn
                    class=" btn-padded"
                >
                  <a style="text-decoration: none;color:#062F6E;padding: 10px 36px;"
                     href="/files/Liikkumisen_motivaatio.pdf"
                     download><span><v-icon
                      v-on="on" dark>file_download</v-icon> {{ $t('movement-motivation-pdf') }}</span></a>
                </v-btn>
              </div>
            </v-flex>
            <v-flex sm12 md4>
              <div class="text-md-right text-lg-right text-xl-right text-center">
                <v-btn
                    class=" btn-padded"
                >
                  <a v-if="$i18n.locale==='fi'"
                     style="text-decoration: none; color:#062F6E;padding: 10px 55px;"
                     href="/files/Arvonnan_saannot.pdf" download><span><v-icon v-on="on"
                                                                               dark>file_download</v-icon> {{
                      $t('prizes-rules-pdf')
                    }}</span></a>
                  <a v-if="$i18n.locale==='en'"
                     style="text-decoration: none; color:#062F6E;padding: 10px 80px"
                     href="/files/R4DM_raffle_rules.pdf" download><span><v-icon v-on="on"
                                                                                dark>file_download</v-icon> {{
                      $t('prizes-rules-pdf')
                    }}</span></a>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
          <v-layout align-center justify-center row>
            <v-flex sm12 md4>
              <div class="text-md-left text-lg-left text-xl-left text-center">
                <v-btn
                    class=" btn-padded"
                    @click="showMoreInfo=!showMoreInfo"
                >
              <span class="mr-2"
                    style="cursor: pointer;">{{ $t('reserve-sport-federations') }}</span>
                  <SportFederationDialog v-model="showMoreInfo"></SportFederationDialog>
                </v-btn>
              </div>
            </v-flex>
            <v-flex sm12 md4>
              <div class="text-center">
                <v-btn
                    class=" btn-padded"
                    @click="showMoreInfo1=!showMoreInfo1"
                >
              <span class="mr-2"
                    style="cursor: pointer;">{{ $t('members-union') }}</span>
                  <MembersUnionsDialog v-model="showMoreInfo1"></MembersUnionsDialog>
                </v-btn>
              </div>
            </v-flex>
            <v-flex sm12 md4>
              <div class="text-md-right text-lg-right text-xl-right text-center">
                <v-btn
                    class=" btn-padded"
                    @click="showMoreInfo2=!showMoreInfo2"
                >
              <span class="mr-2"
                    style="cursor: pointer; margin-left: 8px;">{{ $t('defence-association') }}</span>
                  <DefenceAssociationDialog v-model="showMoreInfo2"></DefenceAssociationDialog>
                </v-btn>
              </div>
            </v-flex>
          </v-layout>
        </v-container>

      </div>
    </v-parallax>
    <div class="width-controller">
      <v-container fluid>
        <v-row>
          <v-col
              class="text-center"
              cols="12"
              justify="center">
            <h1 class="display-1 font-weight-thin mb-4" style="color:#062F6E;">{{ $t('info-page-main-header') }}</h1>
          </v-col>
          <v-col
              cols="12"
              justify="center">
            <div v-html="$t('info-first-par')"/>
            <div v-html="$t('info-second-par')"/>
            <p>{{ $t('info-third-par') }}</p>
            <p>{{ $t('info-fourth-par') }}</p>
            <p>{{ $t('info-fifth-par') }}</p>
          </v-col>
          <v-col
              xs12 sm4
              justify="center">
              <ul style="font-weight:bold;">
                <li>{{ $t("walker-distance-info") }} <span style="padding-left:7px;">"{{ $t("walker") }}"</span></li>
                <li>{{ $t("marcher-distance-info") }} <span style="padding-left:7px;">"{{ $t("marcher") }}"</span></li>
                <li>{{ $t("master-marcher-distance-info") }} <span style="padding-left:7px;">"{{ $t("marcher") }}"</span></li>
                <li>{{ $t("remote-scout-distance-info") }} <span style="padding-left:7px;">"{{ $t("remote-scout") }}"</span></li>
              </ul>
          </v-col>
          <v-col
              cols="12"
              justify="center">
            <p>{{ $t("info-first-intro") }}</p>
            <ul style="font-weight:bold;">
                <li>{{ $t("info-blue-text-group1") }}</li>
                <li>{{ $t("info-blue-text-group2") }}</li>
                <li>{{ $t("info-blue-text-group3") }}</li>
                <li>{{ $t("info-blue-text-group4") }}</li>
                <li>{{ $t("info-blue-text-group5") }}</li>
                <li>{{ $t("info-blue-text-group6") }}</li>
              </ul>
          </v-col>
          <v-col
              cols="12"
              justify="center">
            <p style="color:#062F6E;font-weight:bold;">{{ $t('second-part-header-info') }}</p>
            <div v-html="$t('second-part-paragraphs-info')"/>
          </v-col>
          <v-col
              cols="12"
              justify="center">
            <p style="color:#062F6E;font-weight:bold;">{{ $t('third-part-header-info') }}</p>
            <div v-html="$t('third-part-paragraphs-info')"/>
            <div class="test">
              <div class="customColumn">
                <enlargeable-image :src="require('@/assets/mallikuva1.jpg')"
                                   :src_large="require('@/assets/mallikuva1.jpg')"
                                   max-height="150px" max-width="150px"/>
              </div>
              <div class="customColumn">
                <enlargeable-image :src="require('@/assets/mallikuva3.jpg')"
                                   :src_large="require('@/assets/mallikuva3.jpg')"
                                   max-height="150px" max-width="150px"/>
              </div>
              <div class="customColumn">
                <enlargeable-image :src="require('@/assets/mallikuva4.png')"
                                   :src_large="require('@/assets/mallikuva4.png')"
                                   max-height="150px" max-width="150px"/>
              </div>
              <div class="customColumn">
                <enlargeable-image :src="require('@/assets/mallikuva5.jpg')"
                                   :src_large="require('@/assets/mallikuva5.jpg')"
                                   max-height="150px" max-width="150px"/>
              </div>
              <div class="customColumn">
                <enlargeable-image :src="require('@/assets/mallikuva6.jpg')"
                                   :src_large="require('@/assets/mallikuva6.jpg')"
                                   max-height="150px" max-width="150px"/>
              </div>
              <div class="customColumn">
                <enlargeable-image :src="require('@/assets/mallikuva7.jpg')"
                                   :src_large="require('@/assets/mallikuva7.jpg')"
                                   max-height="150px" max-width="150px"/>
              </div>
            </div>
          </v-col>
          <v-col
              cols="12"
              justify="center">
            <p style="color:#062F6E;font-weight:bold;">{{ $t('fourth-part-header-info') }}</p>
            <div v-html="$t('fourth-part-paragraphs-info')"/>
          </v-col>
          <v-col
              cols="12"
              justify="center" v-if="$i18n.locale==='fi'">
            <p style="color:#062F6E;font-weight:bold;">{{ $t('special-attention') }}</p>
            <div v-html="$t('special-attention-dialogue')"/>
          </v-col>
          <v-col
              cols="12"
              justify="center">
            <p style="color:#062F6E;font-weight:bold;">{{ $t('signature-one-info') }}</p>
            <p style="color:#062F6E;font-weight:bold;">{{ $t('signature-two-info') }}</p>
          </v-col>
        </v-row>

      </v-container>
    </div>
  </div>
</template>

<script>
import SportFederationDialog from "@/components/SportFederationDialog";
import MembersUnionsDialog from "@/components/MembersUnionsDialog";
import DefenceAssociationDialog from "@/components/DefenceAssociationDialog";
import EnlargeableImage from '@diracleo/vue-enlargeable-image';
import Axios from "@/api/apiconfig";

export default {
  name: "InfoPage",
  components: {
    DefenceAssociationDialog,
    MembersUnionsDialog,
    SportFederationDialog,
    EnlargeableImage
  },

  data() {
    return {
      showMoreInfo: false,
      showMoreInfo1: false,
      showMoreInfo2: false,
      post_url: null, //

    }
  },
  methods: {
    getAnnouncements() {
      Axios.get('/user/announcement', {withCredentials: true}).then(data => {
        console.log(data.data.post_url)
        this.post_url = data.data.post_url
      })
    },
  },
  mounted() {
    this.getAnnouncements()
  }

}
</script>

<style>
.v-parallax {
  height: auto !important;
  padding-top: 120px !important;
  padding-bottom: 50px;
}

.width-controller {
  padding-left: 15vw;
  padding-right: 15vw;
}

.btn-padded {
  margin-top: 10px !important;
  width: 275px;
  font-size: 11px;
  color: #062F6E !important;
}

.test > .enlargeable-image > .slot > img.default {
  display: inline-block;
  max-width: 150px !important;
  max-height: 300px !important;
  cursor: zoom-in;
}

.test::after {
  content: "";
  clear: both;
  display: table;
}

.customColumn {
  float: left;
  width: 16%;
  padding: 5px;
}

</style>
