<template>
  <v-container id="resetpw" fluid fill-height class="signup" :style=img_section_style>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-col sm="9" md="12" class="text-center">
          <h1 class="display-1 font-weight-thin mb-4">{{ $t("reset-password") }}</h1>
        </v-col>
        <v-form ref="form" v-model="valid" dark color="white">
          <v-layout align-center justify-center>
            <v-flex sm12 md12>
              <v-text-field
                  name="email"
                  :label="$t('email') + ' *'"
                  type="text"
                  v-model="user.email"
                  :rules="[v => !!v || 'Item is required']"
                  :disabled="loading"
                  required
                  light
                  rounded
                  filled
                  background-color="#fff"
              ></v-text-field>
            </v-flex>
          </v-layout>
        </v-form>

        <v-flex align-self-center sm12 md6>
          <v-spacer></v-spacer>
          <v-btn color="white" @click.stop="resetPass()" :loading="loading">{{
              $t("reset-password")
            }}
          </v-btn>
        </v-flex>
      </v-flex>
    </v-layout>
    <v-snackbar
        v-model="snackbar"
    >
      {{ $t(resp) }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="pink"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>

</template>

<script>
import API from '@/api/apiconfig';

export default {
  name: "ResetPasswordMail",
  data: () => ({
    user: {
      email: null,
    },
    valid: false,
    loading: false,
    resp: null,
    snackbar: false
  }),
  methods: {
    resetPass() {
      API.post('/auth/reset', this.user).then(data => {
        if (data) {
          if (data.status == 200) {
            this.$router.push("/reset_instructions")
          } else {
            this.resp = data.data.message;
            this.snackbar = true;
          }
        }
      })
    }
  },
  computed: {
    img_section_style: function () {
      var bgImg = require('@/assets/r4dm_hero_2024.png')
      return {
        "background": 'url(' + bgImg + ')',
        "background-color": "#062F6E !important",
        "background-size": "cover",
      }
    },
  }
}
</script>

<style scoped>
.signup {
  background-color: #062F6E !important;
  color: white !important;
  padding-top: 30px;
}
</style>