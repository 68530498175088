<template>
  <v-container class="white lighten-5 max-width-medal">
    <v-row class="hidden-sm-and-down">
      <v-col
          sm="12"
          md="4"
          align="center"
      >
        <v-row
            align="center"
            justify="center"
        >
          <v-col
              cols="12">
            <v-img
                class="align-self-center"
                src="@/assets/medal_one_white.png"
                max-width="100px"
            />
            <h2>
              {{ $t('walker') }}
            </h2>
            <h3>
              {{ $t('walker-distance') }}
            </h3>
          </v-col>
        </v-row>
        <v-row
            align="center"
            justify="center"
        >
          <v-col
              cols="12">
            <v-img
                class="align-self-center"
                src="@/assets/medal_one_red.png"
                max-width="100px"
            />
            <h2>
              {{ $t('master-marcher') }}
            </h2>
            <h3>
              {{ $t('master-marcher-distance') }}
            </h3>
          </v-col>
        </v-row>
      </v-col>
      <v-col
          sm="12"
          md="4"
          align="center"
      >
        <v-img
            class="align-self-center"
            src="@/assets/medal_main_center.png"
            max-width="170px"
        />
      </v-col>
      <v-col
          sm="12"
          md="4"
          align="center"
      >
        <v-row
            align="center"
            justify="center"
        >
          <v-col
              cols="12">
            <v-img
                class="align-self-center"
                src="@/assets/medal_one_yellow.png"
                max-width="100px"
            />
            <h2>
              {{ $t('marcher') }}
            </h2>
            <h3>
              {{ $t('marcher-distance') }}
            </h3>
          </v-col>
        </v-row>
        <v-row
            align="center"
            justify="center"
        >
          <v-col
              cols="12">
            <v-img
                class="align-self-center"
                src="@/assets/medal_one_black.png"
                max-width="100px"
            />
            <h2>
              {{ $t('remote-scout') }}
            </h2>
            <h3>
              {{ $t('remote-scout-distance') }}
            </h3>
          </v-col>
        </v-row>
      </v-col>
    </v-row>


    <v-row class="hidden-md-and-up">
      <v-col
          cols="12"
          xs="12"
          sm="12"
          align="center"
      >
        <v-img
            class="align-self-center"
            src="@/assets/medal_main_center.png"
            max-width="100px"
        />
      </v-col>

      <v-col
          cols="12"
          xs="12"
          sm="12"
          align="center"
      >
        <v-img
            class="align-self-center"
            src="@/assets/medal_one_white.png"
            max-width="100px"
        />
        <h2>
          {{ $t('walker') }}
        </h2>
        <h3>
          {{ $t('walker-distance') }}
        </h3>
      </v-col>
      <v-col
          cols="12"
          xs="12"
          sm="12"
          align="center"
      >
        <v-img
            class="align-self-center"
            src="@/assets/medal_one_yellow.png"
            max-width="100px"
        />
        <h2>
          {{ $t('marcher') }}
        </h2>
        <h3>
          {{ $t('marcher-distance') }}
        </h3>
      </v-col>
      <v-col
          cols="12"

          xs="12"
          sm="12"
          align="center"
      >
        <v-img
            class="align-self-center"
            src="@/assets/medal_one_red.png"
            max-width="100px"
        />
        <h2>
          {{ $t('master-marcher') }}
        </h2>
        <h3>
          {{ $t('master-marcher-distance') }}
        </h3>
      </v-col>
      <v-col
          cols="12"

          xs="12"
          sm="12"
          align="center"
      >
        <v-img
            class="align-self-center"
            src="@/assets/medal_one_black.png"
            max-width="100px"
        />
        <h2>
          {{ $t('remote-scout') }}
        </h2>
        <h3>
          {{ $t('remote-scout-distance') }}
        </h3>
      </v-col>

    </v-row>
    <v-row
        class=""
        align="center"
        justify="center"
    >
      <v-col
          class="text-center"
          cols="12"
          style="max-width: 800px;"
      >
        <div v-html="$t('resul-four-day-march-body')"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Medals"
}
</script>

<style scoped>
.max-width-medal {
  max-width: 1080px !important;
}
</style>