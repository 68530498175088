<template>
  <v-card>
    <div class="padders">
      <v-tabs
          v-model="tab"
          centered
          dark
          icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          Unapproved results
        </v-tab>

        <v-tab href="#tab-2">
          Approved results
        </v-tab>
        <v-tab href="#tab-3">
          Announcements
        </v-tab>
      </v-tabs>
    </div>
    <v-tabs-items v-model="tab">
      <v-tab-item value="tab-1">
        <UnapprovedComponent></UnapprovedComponent>
      </v-tab-item>
      <v-tab-item value="tab-2">
        <ApprovedComponent></ApprovedComponent>
      </v-tab-item>
      <v-tab-item value="tab-3">
        <v-form>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  md="4"
              >
                <v-text-field
                    v-model="post_url"
                    label="Facebook embed url"
                    required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
                color="success"
                @click="savePost"
            >
              Save
            </v-btn>

          </v-container>
        </v-form>

      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import UnapprovedComponent from '@/views/CompetitionAdminUnapproved';
import ApprovedComponent from '@/views/CompetitionAdminApproved';
import Axios from "@/api/apiconfig";


export default {
  components: {
    UnapprovedComponent,
    ApprovedComponent
  },
  data() {
    return {
      tab: '#tab-2',
      post_url: null,
    }
  },
  methods: {
    getAnnouncements() {
      Axios.get('/user/announcement', {withCredentials: true}).then(data => {
        console.log(data.data.post_url)
        this.post_url = data.data.post_url
      })
    },
    savePost() {
      Axios.post('/user/announcement', {'post_url': this.post_url}, {withCredentials: true}).then(data => {
        console.log(data)
      })
    }
  },
  mounted() {
    this.getAnnouncements()
  }
}
</script>
<style scoped>
.home-page {
  color: black;
}

.v-parallax {
  height: auto !important;
  padding-top: 120px !important;
  padding-bottom: 50px;
}

.gray {
  background-color: #e0dfdf !important;
}

.blue-background {
  background-color: #062F6E;
}

.text--white {
  color: white;
}

.padders {
  padding-top: 60px;
  background-color: rgba(0, 0, 0, 0.90);
}
</style>

