<template>
  <v-carousel
      cycle
      hide-delimiter-background
      
      height="auto"
  >
    <v-carousel-item
        v-for="(slide, i) in prize"
        :key="i"
    >
      <v-container>
        <v-row
            class="fill-height"
            align="center"
            justify="center"
        >
<!--           <v-col
              cols="12"
              align="center">
            <v-img
                class="align-self-center"
                :src="logo[i]"
                max-width="170px"
                contain
            />
          </v-col> -->
          <v-col
              class="text-center"
              cols="12"
              align="center"
          >
            <div class="text--black">
              <h1 class="display-1 font-weight-thin mb-4 text--black">{{ $t(prizeHeader[i]) }}</h1>
            </div>
          </v-col>
          <v-col
              v-if="slide"
              class="text-center"
              cols="12"
              align="center"
          >
            <div class="text--black">
              <div class="text--black" v-html="$t(slide)"/>
            </div>
          </v-col>
          <v-col
              cols="12"
              align="center">
            <v-img
                class="align-self-center"
                :src="image[i]"
                max-width="500px"
                max-height="320px"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-carousel-item>
  </v-carousel>

</template>

<script>
export default {
  name: "PrizeCarousel",
  data() {
    return {
      prizeHeader: [
        "ecoaims-prize-header",
        "lumonite-prize-header"
      ],
      prize: [
        "ecoaims-prize-body",
        "lumonite-prize-body"
      ],
      image: [
        require('@/assets/prizes/eco_aims_1.jpg'),
        require('@/assets/prizes/Valostoren_arvontakaruselliin_kuva.jpg'),
        
      ],
      /* logo: [
        require('@/assets/prizes_carousel_1_logo.png'),
        require('@/assets/prizes_carousel_2_logo.png'),
        require('@/assets/prizes_carousel_3_logo.png'),
        require('@/assets/prizes_carousel_4_logo.png'),
      ] */
    }
  }
}
</script>

<style scoped>
.text--black {
  color: black;
}
</style>