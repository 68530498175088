<template>
  <v-container class="grey lighten-5">
    <v-row no-gutters>
      <v-col cols="12">
        <v-col sm="12" md="12" class="text-center">
          <v-layout row wrap>
            <v-flex xs4></v-flex>
            <v-flex xs4>
              <h1 style="display:inline;" class="font-weight-thin mb-4">{{ $t("results") }}</h1>
            
            </v-flex>
            <v-flex xs4 class="text-right">
              <v-btn
                @click.stop="$router.push('/2023_results')"
                small
                color="primary"
              >
                <span>{{ $t('old-results-button-2023') }}</span>
              </v-btn>
              <v-btn
                @click.stop="$router.push('/2022_results')"
                small
                color="primary"
              >
                <span>{{ $t('old-results-button-2022') }}</span>
              </v-btn>
              <v-btn
                @click.stop="$router.push('/2021_results')"
                small
                color="primary"
              >
                <span>{{ $t('old-results-button-2021') }}</span>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-col>
        <v-lazy>
          <v-data-table 
            class="max-height"
            :headers="headers"
            :items="results"
            :search="search"
            fixed-header
          >
          
            <template v-slot:top>
              <v-text-field
                v-model="search"
                label="Search"
                class="mx-4"
              ></v-text-field>
            </template>
            <!--  <template v-slot:item.name="props">
                    <td v-if="props.item.user.nick_name">{{ props.item.user.nick_name }}</td> 
                    <td v-else>{{props.item.user.first_name + " " + props.item.user.last_name}}</td>
            </template> -->
              <template v-slot:item.distance_1="props">
                  <td v-if="props.item.accepted_1==1" style= "white-space: nowrap;">
                    {{ formatNumber(props.item.distance_1, 1) }} km <v-img v-if="props.item.extra_weight_1" src="@/assets/r4dm_10kg_icon.png" max-height="20" max-width="15"></v-img>
                  </td>
                  <td v-else>-</td>
              </template>
              <template v-slot:item.distance_2="props">
                  <td v-if="props.item.accepted_2==1" style= "white-space: nowrap;">
                  {{ formatNumber(props.item.distance_2, 1) }} km <v-img v-if="props.item.extra_weight_2" src="@/assets/r4dm_10kg_icon.png" max-height="20" max-width="15"></v-img>
                  </td>
                  <td v-else>-</td>
              </template>
              <template v-slot:item.distance_3="props">
                  <td v-if="props.item.accepted_3==1" style= "white-space: nowrap;">
                    {{ formatNumber(props.item.distance_3, 1) }} km <v-img v-if="props.item.extra_weight_3" src="@/assets/r4dm_10kg_icon.png" max-height="20" max-width="15"></v-img>
                  </td>
                  <td v-else>-</td>
              </template>
              <template v-slot:item.distance_4="props">
                  <td v-if="props.item.accepted_4==1" style= "white-space: nowrap;">
                    {{ formatNumber(props.item.distance_4, 1) }} km <v-img v-if="props.item.extra_weight_4" src="@/assets/r4dm_10kg_icon.png" max-height="20" max-width="15"></v-img>
                  </td>
                  <td v-else>-</td>
              </template>
              <template v-slot:item.user.nationality="props">
                <td>
                    <country-flag :country='props.item.user.nationality' size='normal'/>
                </td>
              </template>
              <template v-slot:item.total_distance="props">
                  <td style= "white-space: nowrap;">
                    {{ formatNumber(props.item.total_distance, 2) }} km </td>
              </template>
          </v-data-table>
        </v-lazy>
      </v-col>
    </v-row>  
  </v-container>
</template>

<script>
import Axios from '@/api/apiconfig'
import CountryFlag from 'vue-country-flag'


export default {
  name: "ResultsView",
  components: {
    CountryFlag
  },
  data: () => ({
    results: [],
    search: '',

  }),
  computed: {
    headers () {
      return [
        {
          text: this.$t('title'),
          align: 'start',
          sortable: false,
          value: 'user.title',
        },
        {
          text: this.$t('name'),
          align: 'start',
          sortable: false,
          value: 'name',
        },
        {
          text: this.$t('organization'),
          align: 'start',
          sortable: false,
          value: 'user.organization',
        },
        {
          text: this.$t('club'),
          align: 'start',
          sortable: false,
          value: 'user.club',
        },
        {
          text: this.$t('team'),
          align: 'start',
          sortable: false,
          value: 'team.team_name',
        },
         {
          text: this.$t('day-one-short'),
          align: 'start',
          sortable: true,
          value: 'distance_1',
        },
         {
          text: this.$t('day-two-short'),
          align: 'start',
          sortable: true,
          value: 'distance_2',
        },
        {
          text: this.$t('day-three-short'),
          align: 'start',
          sortable: true,
          value: 'distance_3',
        },
        {
          text: this.$t('day-four-short'),
          align: 'start',
          sortable: true,
          value: 'distance_4',
        },
        {
          text: this.$t('total-distance'),
          align: 'start',
          sortable: true,
          value: 'total_distance',
        },
        {
          text: this.$t('total-time'),
          align: 'start',
          sortable: true,
          value: 'total',
        },
        {
          text: this.$t('nationality'),
          align: 'start',
          sortable: true,
          value: 'user.nationality',
        },
      ]
    },
  },
  methods: {
    GetResults() {
      Axios.get('/competition/public_results').then(data => {
        if (data.data.data.competition != null) {
        this.results = data.data.data.competition
        for (let i = 0; i < this.results.length; i++) {
          let distances = []
          let result = this.results[i]
          let total_time = this.timeFromMins((result.accepted_1 ? this.timeToMins(result.time_1) : 0) + (result.accepted_2 ? this.timeToMins(result.time_2) : 0) + (result.accepted_3 ? this.timeToMins(result.time_3) : 0) + (result.accepted_4 ? this.timeToMins(result.time_4) : 0))

          //This thing below was added here to make names show up in search
          if (result.user.nick_name) {
            result.name = result.user.nick_name
          } else {
            result.name = result.user.first_name + " " +result.user.last_name;
          }

          if (result.accepted_1) {
            distances.push(result.distance_1)
          }
          if (result.accepted_2) {
            distances.push(result.distance_2)
          }
          if (result.accepted_3) {
            distances.push(result.distance_3)
          }
          if (result.accepted_4) {
            distances.push(result.distance_4)
          }
          result.total_distance = 0
          for (let i = 0; i < distances.length; i++) {
            result.total_distance += distances[i]
          }
          result.total = total_time
        }
        } else {
          console.log("competition was null")
        }
      });
    },
    timeToMins(time){
      var b = time.split(':');
      return b[0]*60 + +b[1];
    },
    timeFromMins (mins){
      function z(n){return (n<10? '0':'') + n;}
      var h = (mins/60 |0);
      var m = mins % 60;
      return z(h) + ':' + z(m);
    },
    formatNumber (value, no){
      if(value == null){
        return "-"
      } else {
        return value.toFixed(no)
      }
    },
    filterText (value, search, item) {
      return value != null &&
          search != null &&
          typeof value === 'string' 
    },
  },
  mounted() {
    this.GetResults()
  }
}
</script>

<style scoped>
.max-height {
  overflow:auto;
}
</style>